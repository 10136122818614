<script setup lang="ts">
import fuzzysort from "fuzzysort";
import { mdiMagnify } from "@mdi/js";

const { orgSidebarLinks } = useAppSidebarLinks();
const { t } = useI18n();

const dialog = ref(false);
const searchQuery = ref("");
const router = useRouter();

const availableLinks = computed(() => {
  if (!orgSidebarLinks.value) return [];
  return Object.values(orgSidebarLinks.value).flatMap((group) => {
    if (!group.renderGuard || !group.renderGuard()) return [];
    return group.links
      .filter((link) => link.renderGuard && link.renderGuard())
      .map((link) => ({
        title: `${group.title} -> ${link.title}`,
        to: link.to,
        icon: link.icon,
      }));
  });
});

const results = computed(() => {
  if (!searchQuery.value) return availableLinks.value;

  const searchResults = fuzzysort.go(searchQuery.value, availableLinks.value, {
    key: "title",
    limit: 15, // Adjust this limit as needed
    threshold: 0.5, // Lower threshold = stricter matching
  });

  return searchResults.map((result) => result.obj);
});

const openSearch = () => {
  dialog.value = true;
};

const route = useRoute();
watch(
  () => route.path,
  () => (dialog.value = false)
);
watch(dialog, () => {
  if (!dialog.value) {
    setTimeout(() => {
      searchQuery.value = "";
    }, 100);
  }
});

const handleGlobalKeydown = (event: KeyboardEvent) => {
  // Check for both "/" and "ظ" (Arabic equivalent) when Ctrl is pressed
  if ((event.key === "/" || event.key === "ظ") && event.ctrlKey) {
    openSearch();
    event.preventDefault();
  }
};

const listItems = useTemplateRef("listItems");
const handleSearchKeydown = (event: KeyboardEvent) => {
  if (event.key === "Enter") {
    handleSubmit();
  } else if (event.key === "ArrowDown") {
    event.preventDefault();
    if (listItems.value && listItems.value[0]) {
      listItems.value[0].$el?.nextElementSibling?.focus();
    }
  }
};

const handleSubmit = () => {
  // go to first result
  if (results.value.length > 0) {
    router.push(results.value[0].to);
  }
};

onMounted(() => {
  window.addEventListener("keydown", handleGlobalKeydown);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleGlobalKeydown);
});
</script>

<template>
  <div class="m-2">
    <!-- Search field -->
    <MTextField
      :prependInnerIcon="mdiMagnify"
      :placeholder="t('search')"
      :modelValue="undefined"
      variant="outlined"
      color="default"
      readonly
      density="compact"
      hideDetails
      @focused="openSearch"
    >
      <template #append-inner>
        <MTooltip :text="t('keyboard-shortcut')">
          <template #activator="{ props }">
            <div
              class="border border-gray-400 rounded-md border-solid px-2 py-1 text-xs"
              dir="ltr"
              v-bind="props"
            >
              Ctrl+/
            </div>
          </template>
        </MTooltip>
      </template>
    </MTextField>

    <!-- Search Dialog -->
    <MDialog
      v-model="dialog"
      maxWidth="60%"
      class="rounded-lg"
      position="top"
    >
      <MCard>
        <MTextField
          v-model="searchQuery"
          :label="t('search')"
          :placeholder="t('type-to-search')"
          variant="outlined"
          color="default"
          clearable
          class="p-4"
          :autofocus="true"
          hideDetails
          density="compact"
          @keydown="handleSearchKeydown"
        />
        <MDivider />
        <div
          class="max-h-100 overflow-auto"
          tabindex="-1"
        >
          <MList class="mx-4">
            <!-- @vue-expect-error -->
            <ListItemLink
              v-for="(result, index) in results"
              ref="listItems"
              :key="index"
              :route="result.to"
              :title="result.title"
              :prependIcon="result.icon"
              tabindex="-1"
            />
          </MList>
        </div>
      </MCard>
    </MDialog>
  </div>
</template>
