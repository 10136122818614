<script setup lang="ts">
import { mdiChevronRight, mdiHome, mdiChevronLeft } from "@mdi/js";
import { useDisplay, useRtl } from "vuetify";
import type { RoutesNamedLocations } from "@typed-router";

type CrumbItem = {
  title: string;
  disabled: boolean;
  to?: RoutesNamedLocations;
  href?: string;
};

// composables
const { isRtl } = useRtl();
const { orgSidebarLinks } = useAppSidebarLinks();
const router = useRouter();
const { mobile } = useDisplay();

const route = useRoute("dashboard-organizations-orgId");
const { title } = usePageTitle();

// computed
const currentRoute = computed(() => {
  const routes = route.name.split("-");

  if (routes.length < 4) {
    return [];
  }

  return routes.slice(3, routes.length);
});

const items = computed<CrumbItem[]>(() => {
  if (
    !currentRoute.value.length ||
    !route.params.orgId ||
    orgSidebarLinks.value === undefined
  ) {
    return [];
  }

  const crumb1 = orgSidebarLinks.value[currentRoute.value[0]];

  if (!crumb1) {
    return [];
  }

  const crumb2 =
    "links" in crumb1
      ? crumb1.links?.find((link) => route.name.startsWith(link.to.name))
      : undefined;

  const crumb3Title = computed(() => {
    return title.value ?? undefined;
  });

  // always show the first title (if not mobile screen)
  const showCrumb1 = !mobile.value;

  // always show the second title (if available)
  const showCrumb2 = !!crumb2;

  // only show the third title if the current route depth > 2 (if it's available) OR crumb2 not available
  const showCrumb3 =
    crumb3Title.value !== undefined &&
    (currentRoute.value.length > 2 || !showCrumb2);

  const crumbs: (CrumbItem | false)[] = [
    showCrumb1 && {
      title: crumb1.title,
      disabled: true,
    },
    showCrumb2 && {
      title: crumb2.title,
      // disabled if routes depth less <= 2
      disabled: currentRoute.value.length <= 2,
      to: currentRoute.value.length <= 2 ? undefined : crumb2.to,
    },
    showCrumb3 && {
      title: crumb3Title.value,
      disabled: true,
    },
  ];

  return crumbs.filter((c) => !!c) as CrumbItem[];
});
</script>

<template>
  <div v-if="orgSidebarLinks !== undefined">
    <div class="flex items-center">
      <div class="m-4">
        <MBtn
          icon
          variant="plain"
          size="large"
          density="compact"
          @click="router.go(-1)"
        >
          <MIcon :icon="isRtl ? mdiChevronRight : mdiChevronLeft" />
        </MBtn>
      </div>
      <div
        class="h-8 w-px from-transparent via-neutral-500 to-transparent bg-gradient-to-tr opacity-20 dark:opacity-100"
      ></div>
      <div class="my-4 ms-4">
        <ul
          class="flex flex-nowrap list-none items-center gap-x-2 text-sm font-medium md:text-base"
        >
          <li class="flex items-center gap-x-1">
            <BtnLink
              :route="{
                name: 'dashboard-organizations-orgId',
                params: { orgId: route.params.orgId },
              }"
              density="compact"
              variant="plain"
              icon
            >
              <MIcon
                :icon="mdiHome"
                size="27"
                class="mb-1"
              />
            </BtnLink>
          </li>

          <li
            v-for="(item, index) in items"
            :key="index"
            class="flex items-center gap-x-2"
          >
            <MIcon
              :icon="isRtl ? mdiChevronLeft : mdiChevronRight"
              size="15"
              class="opacity-50"
            />
            <NuxtLink
              :to="item.to as RoutesNamedLocations"
              :href="item.href"
              class="mt-px text-inherit no-underline opacity-75"
              :class="item.disabled ? undefined : 'hover:underline'"
            >
              {{ item.title }}
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
    <MDivider />
  </div>
</template>
