<script setup lang="ts">
import { VApp } from "vuetify/components/VApp";
import { VMain } from "vuetify/components/VMain";
import { useWindowSize } from "@vueuse/core";

// composables
useLocaleWatcher();
const config = useRuntimeConfig();

// states
const { width } = useWindowSize();
const sidebarOpen = ref(width.value > 1024);
</script>

<template>
  <VApp>
    <AppHeader @toggle:sidebar="sidebarOpen = !sidebarOpen" />
    <AppSidebar v-model="sidebarOpen" />
    <VMain>
      <div class="h-full flex flex-col">
        <AppStagingBanner v-if="config.public.API_TARGET !== 'production'" />
        <AppBreadcrumb />
        <div
          ref="dialogRef"
          class="relative grow pb-8"
        >
          <slot />
        </div>
        <AppFooter />
      </div>
    </VMain>
    <AppSnackbar />
  </VApp>
</template>
